import React from 'react';
import PropTypes from 'prop-types';
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin, FaRedditSquare, FaWhatsappSquare } from 'react-icons/fa';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    RedditShareButton,
} from 'react-share';

import '../styles/Share.scss';

const Share = ({ socialConfig }) => (
    <div className="post-social text-center">
        <FacebookShareButton
            url={socialConfig.config.url}
            resetButtonStyle={false}
            className="btn rounded-pill button is-outlined is-rounded facebook">
            <span className="icon">
                <FaFacebookSquare className="ml-1" />
            </span>
            <span className="text">Facebook</span>
        </FacebookShareButton>
        <TwitterShareButton
            url={socialConfig.config.url}
            resetButtonStyle={false}
            className="button btn rounded-pill is-outlined is-rounded twitter"
            title={socialConfig.config.title}>
            <span className="icon">
                <FaTwitterSquare className="ml-1" />
            </span>
            <span className="text">Twitter</span>
        </TwitterShareButton>
        <LinkedinShareButton
            url={socialConfig.config.url}
            resetButtonStyle={false}
            className="button btn rounded-pill is-outlined is-rounded linkedin"
            title={socialConfig.config.title}>
            <span className="icon">
                <FaLinkedin className="ml-1" />
            </span>
            <span className="text">LinkedIn</span>
        </LinkedinShareButton>
        <RedditShareButton
            url={socialConfig.config.url}
            resetButtonStyle={false}
            className="button btn rounded-pill is-outlined is-rounded reddit"
            title={socialConfig.config.title}>
            <span className="icon">
                <FaRedditSquare className="ml-1" />
            </span>
            <span className="text">Reddit</span>
        </RedditShareButton>
        <WhatsappShareButton
            url={socialConfig.config.url}
            resetButtonStyle={false}
            className="button btn rounded-pill is-outlined is-rounded whatsapp"
            title={socialConfig.config.title}>
            <span className="icon">
                <FaWhatsappSquare className="ml-1" />
            </span>
            <span className="text">WhatsApp</span>
        </WhatsappShareButton>
    </div>
);

Share.propTypes = {
    socialConfig: PropTypes.shape({
        config: PropTypes.shape({
            url: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

export default Share;
