import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Disqus } from 'gatsby-plugin-disqus';
import get from 'lodash/get';
import React, { Component } from 'react';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import LatestArticles from '../components/latest-articles';
import Layout from '../components/layout';
import Seo from '../components/seo';
import FacebookPage from '../components/social/facebook-page';
import InstagramPage from '../components/social/instagram-page';
import Share from '../components/Share';

class BlogPostTemplate extends Component {
    render() {
        const post = get(this.props, 'data.contentfulBlogPost');
        const site = get(this, 'props.data.site.siteMetadata');
        let disqusConfig = {
            url: `${site.siteUrl + this.props.location.pathname}`,
            identifier: post.id,
            title: post.title,
        };
        return (
            <Layout location={this.props.location}>
                <div className="container my-5">
                    <Seo
                        title={`${post.title} | ${site.title}`}
                        description={post.description.description}
                        image={post.image.resize}
                    />

                    <div className={'row ' + post.category}>
                        <section className="col-md-8 my-3 container-white ">
                            <div className="info py-3 px-0">
                                <h1>
                                    {post.title} <span className="badge main-badge">{post.source}</span>
                                </h1>
                                <p className="text-right date">
                                    <span>by {post.author.name}</span> / <span>{post.publishDate}</span>
                                </p>
                            </div>
                            <GatsbyImage
                                className="img-fluid rounded"
                                alt={post.title}
                                image={post.heroImage.gatsbyImageData}
                            />
                            <div className="content">
                                {post.body && (
                                    <div dangerouslySetInnerHTML={{ __html: post.body.childMarkdownRemark.html }} />
                                )}
                                {post.shortStory &&
                                    post.shortStory.map((s) => (
                                        <GatsbyImage
                                            className="img-fluid rounded"
                                            alt={post.title}
                                            image={s.gatsbyImageData}
                                        />
                                    ))}
                            </div>
                            <div>
                                <h5 className="text-center">مشاركة المقال:</h5>
                            </div>
                            <Share
                                socialConfig={{
                                    config: {
                                        url: `${site.siteUrl + this.props.location.pathname}`,
                                        title: post.title,
                                    },
                                }}
                            />
                            <div style={{ marginTop: '50px' }}>
                                <Disqus config={disqusConfig} />
                                <Link to={`/articles/${post.category}`} className="link my-4">
                                    {post.category === 'english-articles' ? (
                                        <FaArrowLeft className="mr-2 ml-0" />
                                    ) : (
                                        <FaArrowRight className="mr-0 ml-2" />
                                    )}

                                    {post.category === 'english-articles' ? 'Back To Articles' : 'الرجوع للمقالات'}
                                </Link>
                            </div>
                        </section>
                        <div className="col-md-4 my-3">
                            <LatestArticles />
                            <FacebookPage link={'https://www.facebook.com/ShaghbattaPsychiatrist/'} />
                            {/* {post.instagram === null ? (
                                <InstagramPage link={'https://www.instagram.com/p/CWTc5o3IkTb/'} />
                            ) : (
                                <InstagramPage link={post.instagram} />
                            )} */}
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($id: String!) {
        contentfulBlogPost(id: { eq: $id }) {
            title
            id
            source
            category
            publishDate(formatString: "MMMM Do, YYYY")
            body {
                childMarkdownRemark {
                    html
                }
            }
            description {
                description
            }
            author {
                name
            }
            instagram
            heroImage {
                gatsbyImageData(layout: FULL_WIDTH)
            }
            shortStory {
                gatsbyImageData(layout: FULL_WIDTH)
            }
            image: heroImage {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        site {
            siteMetadata {
                title
                description
                siteUrl
            }
        }
    }
`;
